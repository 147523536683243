<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">

      <div
        style="width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center tw-items-center tw-self-end tw-cursor-pointer"
        @click="closeModal"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div
        class=" tw-flex card tw-p-10 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px"
      >
        <p
          class="title text-uppercase tw-py-3"
          :style="{ color: companyColor }"
        >
         {{title}}
        </p>

        <label class="desc"> {{ description }} </label>
        <div class="tw-flex tw-w-full tw-flex-col" v-if="assignType === 'pilot'">
        <div class="tw-w-full tw-mb-5">
        <v-combobox :color="companyColor" placeholder="Choose a fleet" label="Chose A Fleet"
                    solo hide-details :items="fleets" item-text="vehicleTagNumber"
                    item-value="id"
                    v-model="fleetId">
          <template v-slot:item="{item}">
            <label>Plate number {{item.vehicleRegNumber}} Tag number {{item.vehicleTagNumber}}</label>
          </template>
        </v-combobox>
        </div>
        <div class="tw-mt-5 tw-w-full">
<!--          <v-combobox v-if="!Object.keys(staffData).length"  :color="companyColor" placeholder="Choose a terminal" label="Chose A terminal"-->
<!--                       solo hide-details :items="terminals" item-text="terminalName"-->
<!--                       item-value="id"-->
<!--                       v-model="terminal">-->
<!--            <template v-slot:item="{item}">-->
<!--              <label>{{item.terminalName}} {{item.address.city}} , {{item.address.state}}</label>-->
<!--            </template>-->
<!--          </v-combobox>-->
          <v-text-field v-if="staffData.terminalAssignedName"  solo hide-details v-model="staffData.terminalAssignedName" disabled>

          </v-text-field>
        </div>
      </div>
        <div class="tw-flex tw-w-full tw-flex-col" v-if="assignType === 'trip'">
          <div class="tw-w-full ">
            <v-combobox :color="companyColor" placeholder="Choose a fleet" label="Chose A Fleet"
                        solo hide-details :items="fleets" item-text="vehicleTagNumber"
                        item-value="id"
                        v-model="fleetId">
              <template v-slot:item="{item}">
                <label>Plate number {{item.vehicleRegNumber}} Tag number {{item.vehicleTagNumber}}</label>
              </template>
            </v-combobox>
          </div>
        </div>

        <div
          class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-mt-10"
        >
          <v-btn
            text
            class="first-btn"
            @click="closeModal"
            :style="{
              color: companyColor,
              border: '1px solid' + companyColor
            }"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            class="second-btn"
            @click="assignFleet"
            :style="{ background: companyColor, color: 'white' }"
            :loading="doneLoading"
          >
            Assign
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/confirm-delete.svg";
import {
  assignFleetToPilot,
  assignFleetToTrip,
  getAllCompanyFleets,
  getTransportCompanyTerminals
} from "@/services/api/APIService";
// import Icons from "../../components/transportCompany/Icons";

export default {
  name: "AssignModal",
  components: {  },
  props: {
    dialog: Boolean,
    icon: [String],
    title: [String],
    description: [String],
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
    pilotId:{
      type:[Number]
    },
    pilotTerminalId:{
        type:[Number]
    },
    tripId: [Number],
    tripTerminalId: [Number],
    assignType: [String],
    departureTerminalId: [Number]
  },
  watch:{
    departureTerminalId : async function (val) {
      if (val) {
        console.log("value found")
        await this.getAllFleets()
        await this.getAllTerminals()
      }
    },
    pilotTerminalId: async function(val){
      if (val){
        await this.getAllFleets()
        await this.getAllTerminals()
      }
    },
    immediate: true
  },

  data() {
    return {
      check,
      companyColor: "#004AAD",
      companyData: {},
      fleets:[],
      fleetId: "",
      doneLoading : false,
      terminals:[],
      terminal: "",
      staffData:{}
    };
  },
  methods:{
    async getAllFleets() {
       await getAllCompanyFleets(this.companyData.id).then(res =>{
         console.log(res.data, "response")
          if (Object.keys(this.staffData).length){
            this.fleets = res.data.filter(fleet => fleet.newDepartureTerminalId === this.staffData.terminalAssignedId && fleet.status === 'ASSIGNED' && !fleet.clone)
          }else  if(this.tripTerminalId){
               this.fleets= res.data.filter(fleet =>fleet.newDepartureTerminalId === this.tripTerminalId && fleet.status === 'ASSIGNED' && !fleet.clone );
          }
          else {
            this.fleets= res.data.filter(fleet => fleet.newDepartureTerminalId === this.pilotTerminalId && fleet.status === 'UNASSIGNED' && !fleet.clone );
          }
        this.loading = false;
       }).catch (err => {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      })
    },
    async getAllTerminals() {
      await getTransportCompanyTerminals(this.companyData.id)
          .then((res) => {
            this.terminals = res.data;
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
    },
    async assignFleet(){
      this.doneLoading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      if (Object.keys(this.staffData).length){
        data.terminalId =this.staffData.terminalAssignedId
      }
      else {
        data.terminalId = this.pilotTerminalId
      }
      data.fleetId = this.fleetId.id
      if (this.assignType === 'pilot'){
        data.pilotId = this.pilotId
      }
      else if (this.assignType === 'trip'){
        data.tripId = this.tripId
        data.terminalId = this.tripTerminalId
      }

      if (this.fleetId) {
        if (this.assignType === 'pilot') {
          await assignFleetToPilot(data).then(() => {
            this.$displaySnackbar({
              message: "Fleet assign to pilot successfully!",
              success: true,
            });
              this.closeModal()
            this.doneLoading = false
          })
              .catch(err => {
                this.doneLoading = false;
                console.log(err.response);
                this.$displaySnackbar({
                  message: err.response.data.message,
                  success: false,
                });
              })
        }
        else if (this.assignType === 'trip'){
          await assignFleetToTrip(data).then(()=>{
            this.$displaySnackbar({
              message: "Fleet assign to trip successfully!",
              success: true,
            });
            this.closeModal()
            this.doneLoading = false
            this.$emit('reloadAllTrips')
          })
              .catch(err => {
                this.doneLoading = false;
                console.log(err.response.data.message);
                this.$displaySnackbar({
                  message: err.response.data.details[0],
                  success: false,
                });
              })
        }
      }
      else {
        this.doneLoading = false
        this.$displaySnackbar({
          message: "Choose a fleet",
          success: false,
        });
      }
    },
     closeModal(){
        this.fleetId = ""
         this.$emit('close')
     }
  },
    mounted() {

    },
    async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    this.companyData = companyData;

    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    if (staffData){
      this.staffData = staffData
    }

    // await this.getAllTerminals()
    await this.getAllFleets()
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (min-width: 768px) {
    width: 30% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}
.desc {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.place-top {
  position: absolute;
  top: 15%;
  /* top: 50px; */
}

.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}
</style>
