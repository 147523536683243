var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[(_vm.loading)?_c('loader',{attrs:{"color":_vm.companyColor,"loading":_vm.loading}}):_c('v-col',{staticClass:"tw-p-0 tw-flex-col ",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between\n       lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8",staticStyle:{"background":"white"}},[_c('div',{staticClass:"table-container lg:tw-pr-8"},[_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center"},[_c('p',{staticClass:"table-header",style:({ color: _vm.companyColor })},[_vm._v("All Pilots")]),_c('div',{staticClass:"tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center"},[_c('div',{staticClass:"mr-2",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":false,"height":"available","hide-default-footer":_vm.pilots.length <= 10,"items":_vm.pilots,"no-data-text":_vm.not_found,"checkbox-color":_vm.companyColor},on:{"click:row":_vm.viewSingle},scopedSlots:_vm._u([{key:"header.sno",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("S/N")])]},proxy:true},{key:"item.sno",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.sno))])]}},{key:"header.profileImage",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Image")])]},proxy:true},{key:"item.profileImage",fn:function(ref){
var item = ref.item;
return [(item.profileImage)?_c('img',{attrs:{"src":item.profileImage,"alt":"terminal"}}):_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center no-image",style:({ backgroundColor: _vm.companyColor })},[_c('p',{staticClass:"tw-text-center tw-p-0 tw-m-0 tw-mb-o"},[_vm._v(" "+_vm._s(item.firstname[0])+" ")])])]}},{key:"header.firstname",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("First Name")])]},proxy:true},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.firstname))])]}},{key:"header.lastname",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Last Name")])]},proxy:true},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.lastname))])]}},{key:"header.email",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Email")])]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.email))])]}},{key:"header.mobile",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Mobile")])]},proxy:true},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.phoneNumber))])]}},{key:"header.country",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Country")])]},proxy:true},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.address.country))])]}},{key:"header.terminal",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Terminal")])]},proxy:true},{key:"item.terminal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.terminalAssigned))])]}},{key:"header.actions",fn:function(){return [_c('span',{staticClass:"status"})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[(_vm.userData.userRole === 'transportCompanyManager'
                           && _vm.staffData.terminalAssignedId === item.terminalAssignedId)?_c('v-list-item',{on:{"click":function($event){return _vm.handleShowAssign(item.id)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_c('img',{staticClass:"tw-mr-2 icon-size",attrs:{"src":require("@/assets/icons/edit-icon.svg"),"alt":""}}),_vm._v(" "+_vm._s(item.fleetAssigned ? 'Re-Assign Fleet' : 'Assign Fleet')+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.viewSingle(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text tw-items-center",staticStyle:{"color":"black"}},[_vm._v(" View "),_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1)])]),_c('assign-modal',{attrs:{"dialog":_vm.showAssign,"title":"Assign fleet to pilot","pilot-id":_vm.pilotId,"assign-type":"pilot"},on:{"close":_vm.handleShowAssign}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }