<template>
  <v-col sm="12">
    <loader :color="companyData.companyColor" v-if="loading" />

    <div v-else class="tw-flex tw-w-full tw-flex-col">
    <button
        class="tw-flex tw-items-center overview-btn tw-mt-20 lg:tw-mt-6"
        @click="backToPilots"
    >
      <icons name="arrow-back" :color="companyData.companyColor" />
      <span class="tw-ml-4" :style="{ color: companyData.companyColor }"
      >All Pilots</span
      >
    </button>
    <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16 tw-px-5">
      <div class="lg:tw-px-12 tw-w-full tw-justify-between tw-flex tw-items-center tw-flex-col-reverse lg:tw-flex-row">
        <v-col sm="12" md="5" class="img-div tw-m-0 tw-p-0 ">
          <img
              :src="pilotData.profileImage"
              v-if="pilotData.profileImage"
              :alt="pilotData.firstname"
          />
          <avatar v-else :avatar="pilotData.firstname.charAt(0)" :color="companyData.companyColor"/>

        </v-col>
        <v-col sm="12" md="6" class="tw-flex tw-flex-col tw-mb-20" v-if="pilotData.fleetAssigned">
          <img class="fleet-img" :src="pilotData.fleetAssigned.vehicleImage" alt="fleet">
          <div class="tw-flex tw-flex-row tw-items-center">
            <v-col class="label-key tw-m-0 tw-py-0 tw-pl-0 tw-pr-2">Plate Number</v-col>
            <v-col  class="label-value tw-m-0 tw-p-0 ">{{pilotData.fleetAssigned.vehicleRegNumber }}</v-col>
          </div>
          <div class="tw-flex tw-flex-row tw-items-center">
            <v-col class="label-key tw-m-0 tw-py-0 tw-pl-0 tw-pr-2">Tag Number</v-col>
            <v-col  class="label-value tw-m-0 tw-p-0 ">{{pilotData.fleetAssigned.vehicleTagNumber }}</v-col>
          </div>
        </v-col>
      </div>

      <v-row class="tw-mt-6 lg:tw-px-12 tw-justify-between tw-flex-col md:tw-flex-row">
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.firstname }}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.lastname }}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.email }}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Mobile</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.phoneNumber }}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.address.houseNumber }}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.address.streetName }}</v-col>
          </div>
        </v-col>

        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Town/City</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.address.city }}</v-col>
          </div>
        </v-col>

        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">State</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.address.state}}</v-col>
          </div>
        </v-col>
        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.terminalAssigned }}</v-col>
          </div>
        </v-col>

        <v-col sm="5">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-col sm="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
            <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{pilotData.address.country }}</v-col>
          </div>
        </v-col>

      </v-row>
    </v-col>
    </div>
  </v-col>
</template>

<script>
import Loader from "../../reuseables/Loader";
import Avatar from "../../reuseables/Avatar";
import Icons from "../../reuseables/Icons";
import {getPilotById} from "../../../services/api/APIService";
export default {
  name: "TerminalManagersinglePilot",
  components: {Icons, Avatar, Loader},
  data() {
    return {
      companyData:{},
      pilotData:{
        address:{}
      },
      loading: false,
    };
  },
  methods: {
    backToPilots() {
      this.$store.dispatch(
        "terminalManagerPilotComponent/setCurrentComponent",
        "allPilots"
      );
      sessionStorage.setItem("terminalManagerPilotComponent", "allPilots");
    },
    async getPilotById(){
      this.loading = true
      let pilotId =  sessionStorage.getItem("pilotId")
      if (pilotId) {
        let data = {}
        data.pilotId = pilotId
        data.transportCompanyId = this.companyData.id
        await getPilotById(data).then(res => {
          this.pilotData = res.data
          this.loading = false
        }).catch(error => {
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
          this.loading = false
        })
      }
    },
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem('companyData'))
    if (companyData){
      this.companyData = companyData
    }
    await this.getPilotById()
  }
};
</script>

<style lang="scss" scoped>
.view-link {
  font-family: "Inter",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}


.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}
.label-key {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.fleet-img{
  height: auto;
  width: 300px;
}
</style>
