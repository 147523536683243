<template>
<div>
  <div v-if="avatarType === 'circle'" class="avatar text-uppercase" :style="{background:color}">{{avatar}}</div>
  <div v-if="avatarType === 'rect'" class="avatar-rect text-uppercase" :style="{background:color}">{{avatar}}</div>
</div>
</template>

<script>
export default {
  name: "Avatar",
  props:{
    avatar : [String],
    color:{
      type: [String],
      default: "#0044AD"
    },
    avatarType:{
      type:[String],
      default: "circle"
    }
  }
}
</script>

<style scoped lang="scss">
.avatar{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;

}

.avatar-rect{
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 58.084px;
  line-height: 70px;
}
</style>