<template>
  <v-col sm="12">
    <loader v-if="loading" :color="companyColor" :loading="loading"/>

    <v-col sm="12" class="tw-p-0 tw-flex-col " v-else>
    <div
        class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between
         lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8"
        style="background: white;"
    >
      <div class="table-container lg:tw-pr-8">
        <div class="tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center">
          <p class="table-header" :style="{ color: companyColor }">All Pilots</p>
          <div class="tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center">
            <div
                class="mr-2"
                style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
            >
              <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
              >
                <template #prepend>
                  <v-icon :color="companyColor" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="companyColor">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>

          </div>
        </div>
        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="false"
            height="available"
            :hide-default-footer="pilots.length <= 10"
            :items="pilots"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
        >
          <template #header.sno>
            <span class="status">S/N</span>
          </template>
          <template v-slot:[`item.sno`]="{ item }">
            <span class="td-style">{{ item.sno }}</span>
          </template>
          <template #header.profileImage>
            <span class="status">Image</span>
          </template>
          <template v-slot:[`item.profileImage`]="{ item }">
            <img
                :src="item.profileImage"
                alt="terminal"
                v-if="item.profileImage"
            />
            <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"

            >
              <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                {{ item.firstname[0] }}
              </p>
            </div>
          </template>
          <template #header.firstname>
            <span class="status">First Name</span>
          </template>
          <template v-slot:[`item.firstname`]="{ item }">
            <span class="td-style">{{ item.firstname }}</span>
          </template>

          <template #header.lastname>
            <span class="status">Last Name</span>
          </template>
          <template v-slot:[`item.lastname`]="{ item }">
            <span class="td-style">{{ item.lastname }}</span>
          </template>
          <template #header.email>
            <span class="status">Email</span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span class="td-style">{{ item.email }}</span>
          </template>
          <template #header.mobile>
            <span class="status">Mobile</span>
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            <span class="td-style">{{ item.phoneNumber }}</span>
          </template>
          <template #header.country>
            <span class="status">Country</span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span class="td-style">{{ item.address.country }}</span>
          </template>
          <template #header.terminal>
            <span class="status">Terminal</span>
          </template>
          <template v-slot:[`item.terminal`]="{ item }">
            <span class="td-style">{{ item.terminalAssigned }}</span>
          </template>
          <template #header.actions>
            <span class="status"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex justify-center">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-list>
                <v-list-item class="" @click="handleShowAssign(item.id)"
                             v-if="userData.userRole === 'transportCompanyManager'
                             && staffData.terminalAssignedId === item.terminalAssignedId">
                  <v-list-item-title
                      style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                    <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"/>
                    {{item.fleetAssigned ? 'Re-Assign Fleet' : 'Assign Fleet'}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="" @click="viewSingle(item)">
                  <v-list-item-title
                      style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer menu-text tw-items-center">

                        View
                    <v-icon :color="companyColor">mdi-arrow-right</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-col>
  <assign-modal :dialog="showAssign" title="Assign fleet to pilot" :pilot-id="pilotId" @close="handleShowAssign" assign-type="pilot" />
  </v-col>
</template>

<script>
import {getAllCompanyPilot} from "../../../services/api/APIService";
import AssignModal from "../../reuseables/AssignModal";
import Loader from "../../reuseables/Loader";


export default {
  name: "TerminalManagerAllPilot",
  components: {Loader, AssignModal},
  data() {
    return {
      pilots: [],
      mostRecent: {},
      companyColor: "#004AAD",
      companyData: {},
      loading: false,
      search: "",
      headers: [
        {text: "", value: "sno"},
        { text: "", value: "profileImage" },
        { text: "", value: "firstname" },
        { text: "", value: "lastname" },
        { text: "", value: "email" },
        { text: "", value: "mobile" },
        { text: "", value: "country" },
        { text: "", value: "terminal" },
        { text: "", value: "actions", align: "end" },
      ],
      selectedRow:[],
      not_found : "Pilots will display here",
      showAssign: false,
      pilotId : null,
      showDelete : false,
      deleteLoading : false,
      userData:{},
      staffData:{}
    };
  },
  methods: {
    selectSingle() {
      this.$store.dispatch(
        "terminalManagerPilotComponent/setCurrentComponent",
        "singlePilot"
      );
      sessionStorage.setItem("terminalManagerPilotComponent", "singlePilot");
    },
    handleShowAssign(id){
      this.pilotId = id
      this.showAssign = !this.showAssign
    },
    handleShowDelete(id){
      this.pilotId = id
      this.showDelete = !this.showDelete
    },
    viewSingle(pilot) {
      this.$store.dispatch(
          "terminalManagerPilotComponent/setCurrentComponent",
          "singlePilot"
      );
      sessionStorage.setItem("terminalManagerPilotComponent", "singlePilot");
      sessionStorage.setItem("pilotId",pilot.id)
    },
    async getAllPilots() {
      this.loading = true;
      try {
        const response = await getAllCompanyPilot(this.companyData.id);
        this.pilots = response.data;
        this.mostRecent = this.pilots[this.pilots.length - 1];
        this.pilots.forEach((pilot, index) => pilot.sno = index + 1)
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    if (staffData){
      this.staffData = staffData
    }
    if (userData){
      this.userData = userData
    }
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllPilots();
  }
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}


::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
.menu-text{
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
}
.icon-size{
  height: 16px;
  width: auto;
}
</style>
