var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-color":"black","overlay-opacity":"0.5"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col"},[_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-self-end tw-cursor-pointer",staticStyle:{"width":"30px","height":"30px","border-radius":"50%","z-index":"1"},on:{"click":_vm.closeModal}},[_c('vue-feather',{staticStyle:{"color":"white"},attrs:{"type":"x"}})],1),_c('div',{staticClass:" tw-flex card tw-p-10 tw-justify-center tw-items-center tw-flex-col",staticStyle:{"min-height":"384px"}},[_c('p',{staticClass:"title text-uppercase tw-py-3",style:({ color: _vm.companyColor })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('label',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(_vm.assignType === 'pilot')?_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col"},[_c('div',{staticClass:"tw-w-full tw-mb-5"},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"placeholder":"Choose a fleet","label":"Chose A Fleet","solo":"","hide-details":"","items":_vm.fleets,"item-text":"vehicleTagNumber","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v("Plate number "+_vm._s(item.vehicleRegNumber)+" Tag number "+_vm._s(item.vehicleTagNumber))])]}}],null,false,1198145361),model:{value:(_vm.fleetId),callback:function ($$v) {_vm.fleetId=$$v},expression:"fleetId"}})],1),_c('div',{staticClass:"tw-mt-5 tw-w-full"},[(_vm.staffData.terminalAssignedName)?_c('v-text-field',{attrs:{"solo":"","hide-details":"","disabled":""},model:{value:(_vm.staffData.terminalAssignedName),callback:function ($$v) {_vm.$set(_vm.staffData, "terminalAssignedName", $$v)},expression:"staffData.terminalAssignedName"}}):_vm._e()],1)]):_vm._e(),(_vm.assignType === 'trip')?_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col"},[_c('div',{staticClass:"tw-w-full "},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"placeholder":"Choose a fleet","label":"Chose A Fleet","solo":"","hide-details":"","items":_vm.fleets,"item-text":"vehicleTagNumber","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v("Plate number "+_vm._s(item.vehicleRegNumber)+" Tag number "+_vm._s(item.vehicleTagNumber))])]}}],null,false,1198145361),model:{value:(_vm.fleetId),callback:function ($$v) {_vm.fleetId=$$v},expression:"fleetId"}})],1)]):_vm._e(),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({
              color: _vm.companyColor,
              border: '1px solid' + _vm.companyColor
            }),attrs:{"text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor, color: 'white' }),attrs:{"text":"","loading":_vm.doneLoading},on:{"click":_vm.assignFleet}},[_vm._v(" Assign ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }