<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllPilots from "./AllPilot";
import SinglePilot from "./SinglePilot";
import { mapGetters } from "vuex";
export default {
  name: "TerminalManagerPilots",
  computed: {
    ...mapGetters("terminalManagerPilotComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allPilots") {
        return AllPilots;
      } else if (component === "singlePilot") {
        return SinglePilot;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("terminalManagerPilotComponent");

    if (!component) {
      sessionStorage.setItem("terminalManagerPilotComponent", "allPilots");
      this.$store.dispatch(
        "terminalManagerPilotComponent/setCurrentComponent",
        "allPilots"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerPilotComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
